<template>
  <div>
    <CCard>
      <CCardHeader>
        <strong>{{
          messages.dealerIncentiveEstimation.basicConditions.toUpperCase()
        }}</strong>
      </CCardHeader>
      <CCardBody>
        <table class="table-basic-conditions" style="width: 100%">
          <thead>
            <th colspan="4">
              {{ messages.products.volumeBonus.toUpperCase() }}
            </th>
          </thead>
          <tbody>
            <tr class="table-basic-conditions table-basic-conditions--products">
              <td v-for="row in tableData" :key="row.key">
                {{ products[row.productCode] }}
                {{ formatNum.get(row.productKey, 2) }} €/UIO
              </td>
            </tr>
            <tr>
              <td v-for="row in tableData" :key="row.key">
                <CIcon
                  v-if="row.complies === 1"
                  :height="42"
                  name="cilSmile"
                  class="icon icon--positive"
                /><CIcon
                  v-if="row.complies === 0"
                  :height="42"
                  name="cilSad"
                  class="icon icon--negative"
                />
                <span :class="completionClasses[row.complies]">{{
                  completionStrs[row.complies].toUpperCase()
                }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import formatNum from "@/utils/formatNum";

export default {
  name: "TableBasicConditions",
  data() {
    return {
      contentTest: "cil-book",
      lang: null,
      messages: null,
      formatNum: formatNum,
    };
  },
  computed: {
    products() {
      return {
        T05: this.messages.products.oil,
        T03: this.messages.products.tyres,
        BPC: this.messages.products.paintAndCons,
        REMANVA: this.messages.products.piecesRemanAndValueAdvantage,
      };
    },
    completionStrs() {
      return [
        this.messages.dealerIncentiveEstimation.doNotComplies,
        this.messages.dealerIncentiveEstimation.complies,
      ];
    },
    completionClasses() {
      return ["doNotComplies", "complies"];
    },
  },
  props: {
    tableData: {
      type: Array,
      required: true,
    },
  },
  created() {
    this.lang = this.$store.getters.getCurrentLang;
    this.messages = this.$store.getters.getLangMessages;
  },
};
</script>

<style lang="scss" scoped>
$low: #da2840;
$high: #60d1b1;
.table-basic-conditions {
  th {
    color: #fff;
    background-color: #666;
    font-weight: 700;
  }
  td,
  th {
    border: 1px solid #d8dbe0;
    padding: 0.75rem;
    text-align: center;
  }
  &--products {
    background-color: #d8dbe0;
    font-weight: 700;
    td {
      border-right: 1px solid #b7b7b7;
      &:last-child {
        border-right: none;
      }
    }
  }
}
.complies {
  display: block;
  color: $high;
}
.doNotComplies {
  display: block;
  color: $low;
}

.c-icon {
  &.icon {
    //display: block;

    &--positive {
      color: $high;
    }

    &--negative {
      color: $low;
    }
  }
}
</style>
